'use strict'
angular.module('cb.enquiry-service', [])
	.factory('EnquiryModalService', ["ApiService", function (ApiService) {
		var EnquiryModalService = {

			getMemoHistory: function (data) {
				try {
					var url = '/webapi/api/v1/memo/viewMemosHistory/' + data.Id;
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getNoteHistory: function (id) {
				try {
					var url = '/webapi/api/v1/note/GetNotes/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			addNote: function (data) {
				try {
					var url = '/webapi/api/v1/note/AddNote';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			addQuoteOptions: function (data) {
				try {
					var url = '/webapi/api/v1/quoteOption/AddQuoteOptions';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			updateQuoteOptions: function (data) {
				try {
					var url = '/webapi/api/v1/quoteOption/UpdateQuoteOptions';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			getQuoteOptions: function (leadId, type) {
				try {
					var url = '/webapi/api/v1/quoteOption/GetQuoteOptions/' + leadId + '/' + type;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			addMultipleNotes: function (data) {
				try {
					var url = '/webapi/api/v1/note/AddMultipleNotes/';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getFiles: function (data) {
				try {
					var url = '/webapi/api/v1/file/GetFiles';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			getLetterTemplatesOnCloud: function (data) {
				try {
					var url = '/webapi/api/v1/file/getLetterTemplatesOnCloud';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			BlobFileUpload: function (blob, CurrentProfile, filename, mimeType) {
				try {
					if (blob != null) {
						var fileReader = new FileReader();
						fileReader.onload = function (e) {
							var data = {
								CurrentProfile: CurrentProfile,
								FileContent: Array.from(new Uint8Array(e.target.result)),
								FileExtension: mimeType,
								FileName: moment().format('YYYY-MM-DD_HH-mm-ss') + "_" + filename
							};
							var url = '/webapi/api/v1/file/uploadFile';
							return ApiService.promisePost(url, data);
						}
						fileReader.readAsArrayBuffer(blob);
					}
				} catch (e) {
					console.error('Error!', e);
				}
			},
			AddFiles: function (data) {
				try {
					var url = '/webapi/api/v1/file/uploadFile';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			downloadFile: function (data) {
				//alert(JSON.stringify(data));
				try {
					var url = '/webapi/api/v1/file/download';
					if (confirm('Are you sure you want to download ' + data.SelectedFileName + data.SelectedFileType + '?')) {
						return ApiService.promisePost(url, data);
					}
				} catch (error) {
					console.error(error)
				}
			},
			deleteFile: function (data) {
				//alert(JSON.stringify(data));
				try {
					var url = '/webapi/api/v1/file/delete';
					if (confirm('Are you sure you want to delete ' + data.SelectedFileName + data.SelectedFileType + '?')) {
						return ApiService.promisePost(url, data);
					}
				} catch (error) {
					console.error(error)
				}
			},
			downloadTemplate: function (data) {
				try {
					var url = '/webapi/api/v1/file/downloadTemplate';
					if (data.LetterLocation != "Static" && data.ViewLocation != "Static Files") {
						if (confirm('Are you sure you want to use ' + data.SelectedFileName + data.SelectedFileType + ' as your letter?')) {
							return ApiService.promisePost(url, data);
						}
					}
					else
						return ApiService.promisePost(url, data);
				} catch (error) {
					console.error(error)
				}
			},

			getActivityLog: function (id) {
				try {
					var url = '';
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			sendLetter: function (id, data, file) {
				try {
					var url = '/webapi/api/v1/send/Letter/' + id;
					return ApiService.promiseUpload(url, data, file);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			sendBrochure: function (id, data) {
				try {
					var url = '';
					return ApiService.promisePost(url, id, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			sendQuote: function (id, data) {
				try {
					var url = '';
					return ApiService.promisePost(url, id, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getAppointmentsLog: function (id) {
				try {
					var url = '/webapi/api/v1/appointment/entity/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			sendMessage: function (id, data) {
				try {
					var url = '';
					return ApiService.promisePost(url, id, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			addMemo: function (data) {
				try {
					var url = '/webapi/api/v1/memo/createMemo';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getMemo: function (id) {
				try {
					var url = '/webapi/api/v1/memo/viewMemo/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getStaff: function (includeLeaving = false) {
				try {
					var url = '/webapi/api/v1/staff/getAll/' + includeLeaving;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			getGroup: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/getUserGroups';
					return ApiService.promisePost(url,data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getLinkedEnquiries: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/getLinkedEnquiries';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			createAlert: function (data) {
				try {
					var url = '/webapi/api/v1/alert/createAlert';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			updateAlternativeContactItem: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/updateAlternativeContactItem';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			pinNote: function (data) {
				try {
					var url = '/webapi/api/v1/note/pinNote';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			deleteNote: function (data) {
				try {
					var url = '/webapi/api/v1/note/deleteNote';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			updateNote: function (data) {
				try {
					var url = '/webapi/api/v1/note/updateNote';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			SynoLogin: function (data) {
				try {
					var url = '/webapi/api/v1/file/SynoLogin';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			SynoLogout: function (data) {
				try {
					var url = '/webapi/api/v1/file/SynoLogout';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			SynoLinkAccount: function (data) {
				try {
					var url = '/webapi/api/v1/file/SynoLinkAccount';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			fetchCloudFileNumber: function (data) {
				try {
					var url = '/webapi/api/v1/file/FetchCloudFileNumber';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getSalesPersonBySaleArea: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/salesPersonBySaleArea/' + data;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			findExistingAddress: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/findExistingAddress/' + data;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			}
		};

		return EnquiryModalService;
	}]);
